import React, { useEffect, useState, useRef, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getEntityNames } from 'api/EntitiesAPI'
import { useTranslation } from 'react-i18next'
import { validIdsURL } from 'utils/regularExpresion'
import axios from 'axios'

import { CardSelect, Loading, RadioCheckAttribute } from '../'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'
import imgLupa from '../../assets/shape.svg'
import './AttributeSelector.css'
import useWindowSize from 'hooks/useWindowSize'

const AttributeSelector = props => {
  const { t } = useTranslation('createPromo')

  const [confirmationCallback, setConfirmationCallback] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [attributeLocal, setAttributeLocal] = useState(props.AttributeCurrent)
  const [entityOptLocal, setEntityOptLocal] = useState(props.entityOptions ? props.entityOptions : []) //para seleccionar
  const [idsActive, setIdsActive] = useState(props.AttributeCurrent ? props.AttributeCurrent.ids : []) //seleccionados
  const [idsActiveLocal, setIdsActiveLocal] = useState(props.AttributeCurrent ? props.AttributeCurrent.ids : [])
  const [newchanges, setNewchanges] = useState(false)
  const [hasChanges, setHasChanges] = useState(false) // Nueva variable de estado
  const [actives, setActives] = useState([])
  const scrollRef = useRef(null)

  const haveTheSameElements = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false
    }
    const sortedArr1 = arr1.slice().sort()
    const sortedArr2 = arr2.slice().sort()
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    const activeLength = idsActive?.length || 0
    const localLength = idsActiveLocal?.length || 0

    if (!props.withCheck) {
      if (actives[0]?.value && idsActive[0]?.entity_id) {
        return setHasChanges(actives[0].value !== idsActive[0].entity_id)
      }

      if (idsActive[0]?.entity_id) {
        return setHasChanges(idsActive[0].entity_id !== idsActiveLocal[0])
      }

      if (idsActiveLocal[0]?.entity_id) {
        return setHasChanges(idsActiveLocal[0]?.entity_id !== idsActive[0])
      }

      return setHasChanges(idsActive[0] !== idsActiveLocal[0])
    }

    if (activeLength !== localLength) {
      setHasChanges(true)
      return
    }

    if (activeLength === localLength) {
      setHasChanges(!idsActive.every(item => idsActiveLocal.includes(item)))
    }
  }, [idsActive, idsActiveLocal, props.withChecks, props.AttributeCurrent, actives])

  // Tamaño/altura de pantalla
  const { height } = useWindowSize()

  useEffect(() => {
    let arrayInvalid = validIdsURL(idsActiveLocal)
    if (arrayInvalid.length > 0) {
      setNewchanges(false)
    } else {
      if (idsActive) {
        setNewchanges(!haveTheSameElements(idsActive, idsActiveLocal))
        setHasChanges(!haveTheSameElements(idsActive, idsActiveLocal))
      }
    }
  }, [idsActive, idsActiveLocal, setNewchanges])

  useEffect(() => {
    let source = axios.CancelToken.source()

    // Obtener los nombres de los ya tildados

    if (attributeLocal && attributeLocal.ids.length !== 0) {
      const fetchData = async () => {
        try {
          const response = await getEntityNames(props.byEntity.endpoint, attributeLocal.ids, source.token)
          if (Array.isArray(response.data)) {
            setActives(
              response.data.map(({ id, description }) =>
                props.byEntity.endpoint !== '/product'
                  ? { value: id, label: description }
                  : { value: id, label: id + ' - ' + description }
              )
            )
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.warn('Request canceled', error.message)
          } else if (error?.response?.status) {
            // Verificación de nulidad opcional
            console.error('Error fetching data:', error.response.status)
          } else {
            console.error('Error fetching data:', error)
          }
        }
      }

      fetchData()
    }

    return () => {
      source.cancel('Componente desmontado')
    }
  }, [attributeLocal, props.byEntity])

  useEffect(() => {
    //obtener los primeros
    if (props.openSelect && props.byEntity && props.byEntity.endpoint && entityOptLocal.length === 0)
      props.onEntitySelectOpen(
        props.fetchedAllEntityPages,
        props.setFetchedAllEntityPages,
        props.setEntityPage,
        props.filterEntityBy,
        props.entityPage,
        props.byEntity.endpoint,
        props.setEntityOptions
      )
  }, [
    props.openSelect,
    props.fetchedAllEntityPages,
    props.setFetchedAllEntityPages,
    props.setEntityPage,
    props.filterEntityBy,
    props.entityPage,
    props.byEntity,
    props.setEntityOptions,
  ])

  useEffect(() => {
    let aux = []
    if (props.entityOptions && props.entityOptions.length) aux = props.entityOptions
    setEntityOptLocal(aux)
  }, [props.entityOptions])

  const saveAttributes = () => {
    props.setEntities(actives)
    props.setOpenSelect(false)
  }

  const handleConfirm = () => {
    saveAttributes()
    setIsOpen(false)
  }

  const handleCancelConfirmation = () => {
    if (!props.withCheck) {
      setIdsActiveLocal(props.AttributeCurrent?.ids)
    }
    setActives(props.AttributeCurrent?.ids)
    setIdsActive(props.AttributeCurrent?.ids)
    props.setOpenSelect(false)
    setIsOpen(false)
  }

  const cancelAttributes = () => {
    if (!hasChanges) {
      return handleCancelConfirmation()
    }
    setConfirmationCallback({
      handleConfirm,
      handleCancel: handleCancelConfirmation,
    })
    setIsOpen(true)
  }

  function handleChange(e) {
    props.setFilterEntityBy(e.target.value)
  }

  const { AttributeCurrent } = props

  useEffect(() => {
    if (!props.loading) {
      setAttributeLocal(AttributeCurrent)
    }
  }, [AttributeCurrent, props.loading])

  useEffect(() => {
    const handleWheel = event => {
      if (scrollRef.current && event.deltaY !== 0) {
        event.preventDefault()
        scrollRef.current.scrollLeft += event.deltaY
      }
    }

    const container = scrollRef.current
    if (container) {
      container.addEventListener('wheel', handleWheel)
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel)
      }
    }
  }, [])

  return (
    <>
      <PopUpAlert
        value={t('confirmPopUp.title')}
        extraText={t('confirmPopUp.description')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={{
          value: t('confirmPopUp.confirm'),
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
        onCancel={{
          value: t('confirmPopUp.cancel'),
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
      />
      <div
        className={`SelectAttribute-modal ${props.openSelect === true && 'is-open'} ${
          !props.attributeSelectorOpen ? 'onlyAttribute' : ''
        }`}>
        <div className="SelectAttribute-container">
          <div className="SelectAttribute-container-header">
            <button type="button" onClick={cancelAttributes}>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <path
                  d="M-1.52588e-05 6L0.63057 6.7491L5.06554 12L6.7356 10.5018L3.89444 7.1415L13 7.1415V4.8585L3.89444 4.8585L6.7356 1.4982L5.06554 -7.62939e-06L0.63057 5.25091L-1.52588e-05 6Z"
                  fill="#971B00"
                />
              </svg>
            </button>
            <h2>{t(props.byEntity?.name)}</h2>
          </div>
          <div className="SelectAttribute-container-body">
            <div className="SelectAttribute-filter">
              <img src={imgLupa} alt="lupa.jpg" />
              <input
                type="text"
                placeholder="Buscar"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                value={props.filterEntityBy}
                onChange={handleChange}
              />
            </div>
            {props.withCheck !== false && (
              <div
                className={`SelectAttribute-list-card scroll ${actives.length > 0 ? 'withCards' : ''}`}
                ref={scrollRef}>
                {' '}
                {actives &&
                  actives.length > 0 &&
                  actives.map(entity => (
                    <CardSelect
                      key={'' + entity.value + entity.value}
                      entity={entity}
                      setActives={setActives}
                      setIdsActiveLocal={setIdsActiveLocal}
                      actives={actives}
                      withValue
                    />
                  ))}
              </div>
            )}
            {props.filterEntityBy === '' && (
              <InfiniteScroll
                dataLength={entityOptLocal.length}
                height={height ? (height < 620 ? 250 : height - 330) : 460}
                next={() =>
                  props.onEntitySelectOpen(
                    props.fetchedAllEntityPages,
                    props.setFetchedAllEntityPages,
                    props.setEntityPage,
                    props.filterEntityBy,
                    props.entityPage,
                    props.byEntity.endpoint,
                    props.setEntityOptions
                  )
                }
                hasMore={!props.fetchedAllEntityPages}
                className="scroll"
                loader={<Loading />}>
                <div className="SelectAttribute-list-select">
                  {entityOptLocal && entityOptLocal.length === 0 && props.loading === false && (
                    <p>No se encontraron resultados</p>
                  )}
                  {entityOptLocal &&
                    entityOptLocal.length > 0 &&
                    entityOptLocal.map(entity => (
                      <RadioCheckAttribute
                        key={entity.value}
                        entity={entity}
                        setActives={setActives}
                        setIdsActiveLocal={setIdsActiveLocal}
                        actives={actives}
                        withCheck={props.withCheck !== undefined ? props.withCheck : true}
                      />
                    ))}
                </div>
              </InfiniteScroll>
            )}
            {props.filterEntityBy !== '' && (
              <div className="SelectAttribute-list-select-filter scroll">
                {props.filteredEntityOptions && props.filteredEntityOptions.length === 0 && props.loading === false && (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>No se encontraron resultados</p>
                  </div>
                )}

                {props.loading === true ? (
                  <Loading />
                ) : (
                  <div>
                    {props.filteredEntityOptions &&
                      props.filteredEntityOptions.length > 0 &&
                      props.filteredEntityOptions.map(entity => (
                        <RadioCheckAttribute
                          key={entity.value}
                          entity={entity}
                          setActives={setActives}
                          setIdsActiveLocal={setIdsActiveLocal}
                          actives={actives}
                          withCheck={props.withCheck !== undefined ? props.withCheck : true}
                        />
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="SelectAttribute-container-footer">
            <button type="button" onClick={cancelAttributes} className={`btn-style btn-style-red`}>
              Cancelar
            </button>
            <button
              type="button"
              color="primary"
              className="btn-style btn-style-orange"
              disabled={!newchanges}
              onClick={saveAttributes}>
              Seleccionar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AttributeSelector
